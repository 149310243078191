import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/common/layout/layout"
import PrivateContent from "../components/user/privatecontent"

import { navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import PageBanner from "../components/common/misc/pagebanner"

const LessonPlanContainer = (props) => {
  return (
    <Layout>
      <PrivateContent accessLevel={"requirespaidsub"}>
        <LessonPlanView
          playTitle={props.pageContext.playTitle}
          playId={props.pageContext.playId}
          sectionTitle={props.pageContext.sectionTitle}
          plans={props.pageContext.plans}
          banners={props.data.banners}
        />
      </PrivateContent>
    </Layout>
  )
}

const LessonPlanView = (props) => {
  const gotoPlan = (e, planId) => {
    e.preventDefault()
    let path = `/plays/${props.playId}/lessonplans/${planId}`
    navigate(path)
  }

  const gotoScene = (e, sceneId) => {
    e.preventDefault()
    let path = `/plays/${props.playId}/${sceneId}_SX001`
    navigate(path)
  }

  let bannerMappings = {}

  for (var edge of props.banners.edges) {
    let node = edge.node
    let i = node.relativePath.lastIndexOf("/")
    let sceneId = node.relativePath.substring(
      i + 1,
      node.relativePath.length - 4
    )
    bannerMappings[`${sceneId}`] = node.childImageSharp.gatsbyImageData
  }

  let lps = props.plans.map((plan) => {
    return (
      <div className="box has-background-sibgrey3light mb-5" key={plan.scene}>
        <div className="title block is-5">{plan.title}</div>
        <div className="block play-home-tile-hover">
          <span>
            {plan.desc} -
            <span
              className="title block is-6 has-text-sibredorange"
              aria-hidden="true"
              onClick={(e) => gotoScene(e, plan.scene)}
              onKeyDown={(e) => gotoScene(e, plan.scene)}
            >
              {" "}
              Go to Scene
            </span>
          </span>
        </div>
        <div
          className="play-home-tile-hover"
          aria-hidden="true"
          onClick={(e) => gotoPlan(e, plan.scene)}
          onKeyDown={(e) => gotoPlan(e, plan.scene)}
        >
          <GatsbyImage
            image={bannerMappings[props.playId + '_' +plan.scene]}
            objectFit="cover"
            objectPosition="50% 50%"
            alt=""
          />
          <div className="button is-black has-text-white mt-1 is-fullwidth">
            View Lesson Plan
          </div>
        </div>
      </div>
    )
  })
  var userGreet = "Welcome!"

  if (props.subInfo) {
    userGreet = props.subInfo.userGreet
  }
  return (
    <>
      <PageBanner title={`${props.sectionTitle} - ${props.playTitle}`} userGreet={userGreet} />
      <div className="container user-login-form-container pt-1">
        <div className="columns profile-form-outer">
            <div className="column is-half">{lps}</div>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query LessonPlanBanners($bannerRegex: String) {
    banners: allFile(filter: { relativePath: { regex: $bannerRegex } }) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
`

export default LessonPlanContainer
